/* Navigation */
.navigation {
	position:absolute;
	height:auto;
	width:100vw;
	z-index:1000;
	background-color:RGBA(0,0,0,0.5);
	text-align:center;
}
.nav-ul {
	width:100%;
	padding:0;
	margin:0;
	list-style-type:none;
	white-space: nowrap;
	overflow-x: auto;
	overflow-y: hidden;
	display: inline-block;
	align-self: center;
}
.nav-ul > li {
	display:inline-block;
	font-size:2em;
	padding:20px;
}
.nav-ul > li > a {
	text-decoration:none;
	font-weight:bold;
	color:RGB(253, 94, 83);
	text-shadow:1px 1px 1px black;
	padding:20px;
}

/* Content Body */
.body-wrapper {
	position: absolute;
	top: 100px;
	left: 1vw;
	border-radius: 30px;
	max-height: 800px;
	overflow-y: hidden;
	width: 98vw;
	z-index: 1000;
	background-color: RGBA(0, 0, 0, 0.5);
	box-sizing: border-box;
}
.body {
	overflow-y: scroll;
	max-height: 800px;
	padding: 10px;
}
.body > .content {
	font-size: 1.2em;
	color:white;
	text-shadow:1px 1px 1px black;
}
.body > .content > h1 {
	font-size:3em;
	text-align:center;
	margin-bottom:0;
}
.body > .content > h2 {
	font-size:2em;
	text-align:center;
	margin-top:0;
}
.body > .content a {
	color:white;
	text-decoration:none;
	font-weight:bold;
	padding:5px 10px;
	border-radius:10px;
	background-color:RGBA(0, 0, 0, 0.5);
}
.body > .content a:hover, .body > .content a:focus {
	color:RGB(0, 0, 0);
	background-color:white;
	text-shadow:none;
}

body {
	background-color:RGBA(253, 94, 83, 0.5);
	margin:0;
	overflow-x: hidden;
}
.cloud {
	position: absolute;
	width: 1000px; /* adjust to your desired size */
	height: 500px; /* adjust to your desired size */
	background: #fff; /* adjust to your desired color */
	border-radius: 50%; /* adjust to your desired shape */
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* add a subtle shadow */
}
.cloud-0 {
	top:-300px;
	left:-600px;
}
.cloud-1 {
	top:-300px;
}
.cloud-2 {
	top:-400px;
	left:600px;
}
.cloud-3 {
	top:-300px;
	left:1200px;
	z-index:10;
}
.cloud-4 {
	top:-200px;
	left:1800px;
}
.cloud-5 {
	top:-200px;
	left:2400px;
}
.road {
	position: absolute;
	width: 100%;
	height: 50px;
	background-color: #333;
}
.road-top {
	border-bottom:15px dashed yellow;
	bottom: 50px;
}
.road-bottom {
	border-top:15px dashed yellow;
	bottom: 0;
}
.car-body {
	position: absolute;
	width: 200px;
	height: 100px;
	background-color: #121363;
	border-radius: 10px;
	bottom:125px;
	left:50px;
}
.car-nose {
	position: absolute;
	width: 75px;
	height: 50px;
	background-color: #121363;
	border-radius: 10px;
	bottom:125px;
	left:200px;
}
.windshield {
	position: absolute;
	width: 50px;
	height: 50px;
	background-color: #add8e6;
	border-top-right-radius: 10px;
	bottom:175px;
	left:210px;
	z-index:10;
}
.wheel {
	position: absolute;
	width: 15px;
	height: 15px;
	border-radius: 50%;
	background-color: #fff;
	border:15px solid black;
	bottom:100px;
}
.wheel-1 {
	left:75px;
}
.wheel-2 {
	left:175px;
}

.car-move {
	animation: car-move 1500ms linear infinite;
	animation-delay: 2s;
}
.wheel-move {
	animation: wheel-move 1500ms linear infinite;
	animation-delay: 2s;
}

@keyframes car-move {
	0% {
		transform: translateY(0%);
	}
	5% {
		transform: translateY(-5px);
	}
	10% {
		transform: translateY(0%);
	}
	100% {
		transform: translateY(0%);
	}
}

@keyframes wheel-move {
	0% {
		transform: translateY(0%);
	}
	5% {
		transform: translateY(-10px);
	}
	10% {
		transform: translateY(0%);
	}
	100% {
		transform: translateY(0%);
	}
}

/* Linked Items -- Jobs, Projects */
.job_list {
	text-align:left;
}
a.job_list_item {
	color: #357866;
  padding: 20px 50px;
  border: thin solid;
  border-radius: 25px;
  margin-bottom: 30px;
	display:block;
}
a.job_list_item:hover, a.job_list_item:focus{
	text-decoration:none!important;
	background: #e7e1e1!important;
	color:#13181e;
	cursor:pointer;
}
.job_list_item > h3 {
	margin-bottom:0;
}
.job_list_item > h3 > span {
	color:RGB(253, 94, 83);
	text-shadow:none;
}
a.job_list_item:hover > h3 {
	color:black;
	text-shadow:none;
}
.job_list_item > p.duration {
	color:rgb(109, 109, 109);
	margin-top:0px;
}
a.job_list_item:hover > p.duration {
	color:#121363;
	margin-top:0px;
	text-shadow:none;
}

/* Scrollbar */
/* width */
::-webkit-scrollbar {
	width: 10px;
	height:10px;
	border-radius:30px;
	margin-left:20px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #f1f1f1; 
	border-radius:30px;
	height:20px;
	box-shadow: inset 0 0 5px grey;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #333;
	height:20px;
	border-radius:30px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: RGB(253, 94, 83);
	height:20px;
	border-radius:30px;
}